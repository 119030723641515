import ExcelExport from "@/services/exportFile/exportToExcel";
import printJS from "print-js";
// import html2pdf from "html2pdf.js";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

const downLoadExcel = async (
  selectedFileType,
  element,
  fileName,
  fileHeaderToExport,
  fileToExport
) => {
  if (selectedFileType === "pdf") {
    // const element = document.getElementById("element-to-print");
    if (!element) {
      console.error("Element to print not found.");
      return;
    }

    try {
      const canvas = await html2canvas(element, {
        scale: 1.5,
        useCORS: true,
      });

      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF({
        orientation: "portrait",
        unit: "in",
        format: "letter",
      });

      const imgWidth = 8.5; // Letter width in inches
      const pageHeight = 11; // Letter height in inches
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      let heightLeft = imgHeight;
      let position = 0;

      pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft > 0) {
        position -= pageHeight;
        pdf.addPage();
        pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }

      pdf.save(`${fileName}.pdf`);
    } catch (error) {
      console.error("Error generating PDF:", error);
    }
    
  } else {
    const filterVal = fileHeaderToExport.map((i, index) => index);
    const list = fileToExport;
    const header = fileHeaderToExport;
    console.log(filterVal);
    console.log(fileHeaderToExport);

    ExcelExport.exportFile(
      filterVal,
      list,
      header,
      fileName.name,
      selectedFileType.name
    );
  }
};

const tableHeaderToJson = (_th) => {
  // let _htmlToJSON = function(){
  // let _tr = _table.getElementsByTagName("tr")[index];  //fix
  // let _th = document.getElementsByTagName("th");
  let _arr = [].map
    .call(_th, function (th) {
      return th.innerHTML;
    })
    .join(",");
  let _data = _arr.split(",");
  console.log(_data);
  console.log("html to JSON", _data);
  // emit('data-header-to-export', _data)
  return Array.from(_data);
  // };
  // _htmlToJSON();
};

const tableToJson = (_table) => {
  let _trLength = _table.getElementsByTagName("tr").length;
  let _jsonData = [];
  let _obj = {};

  let _htmlToJSON = function (index) {
    let _tr = _table.getElementsByTagName("tr")[index];
    let _td = _tr.getElementsByTagName("td");
    let _arr = [].map
      .call(_td, function (td) {
        return td.innerHTML;
      })
      .join(",");
    let _data = _arr.split(",");
    // console.log(_data)

    _obj = Object.assign({}, _data);

    _jsonData.push(_obj);
  };
  for (var i = 1; i < _trLength; i++) {
    _htmlToJSON(i);
  }
  console.log("html to JSON", _jsonData);
  return _jsonData;
  // emit('data-to-export', _jsonData)
};

export default {
  tableToJson,
  tableHeaderToJson,
  downLoadExcel,
  printJS,
};
